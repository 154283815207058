@media(max-width:767px) {
    .hero-slides .carousel-slide .carousel-el {
        padding: 90px 15px 40px;
    }
    
    .hero-slides .carousel-el.apps-cell .container-illu.mobile {
        order: 1;
    }

    .hero-slides .asset-content {
        margin-top: 1.5rem;
        order: 2
    }

    .hero-slides .carousel-el.apps-cell .container-illu.asset-content {
        order: 2
    }

    .hero-slides .carousel-el.apps-cell .container-illu.desktop {
        order: 3;
        margin-top: 2rem
    }
    
    .map-marker:nth-child(1) {
        top: 35px;
        left: 3.25rem
    }
    .map-marker:nth-child(2) {
        top: 65px;
        left: 4.85rem
    }
    .map-marker:nth-child(3) {
        top: 120px;
        left: 6.5rem
    }
    .map-marker:nth-child(4) {
        top: 40px;
        left: 9.75rem
    }
    .map-marker:nth-child(5) {
        top: 45px;
        left: 10.5rem
    }
    .map-marker:nth-child(6) {
        top: 50px;
        left: 11.5rem
    }
    .map-marker:nth-child(7) {
        top: 65px;
        left: 12.5rem
    }
    .map-marker:nth-child(8) {
        top: 70px;
        left: 13.25rem
    }
    .map-marker:nth-child(9) {
        top: 80px;
        left: 12.75rem
    }
    .map-marker:nth-child(10) {
        top: 80px;
        left: 13.5rem
    }
    .map-marker:nth-child(11) {
        top: 73px;
        left: 13.95rem
    }
    .map-marker:nth-child(12) {
        top: 82px;
        left: 14.25rem
    }
    .map-marker:nth-child(13) {
        top: 90px;
        left: 11.5rem
    }
    .map-marker:nth-child(14) {
        top: 120px;
        left: 11rem
    }
    .map-marker:nth-child(15) {
        top: 85px;
        left: 15.25rem
    }
    .map-marker:nth-child(16) {
        top: 42px;
        left: 16.8rem
    }
    .map-marker:nth-child(17) {
        top: 14px;
        left: 19rem
    }
    .map-marker:nth-child(18) {
        top: 105px;
        left: 17.25rem
    }
    .map-marker:nth-child(19) {
        top: 120px;
        left: 17.65rem
    }

    .map-marker:nth-child(20) {
        top: 185px;
        left: 27rem;
        transition-delay: 2.75s
    }
    
    .map-marker:nth-child(21) {
        top: 185px;
        left: 27rem;
        transition-delay: 2.75s
    }
    
    .map-marker:nth-child(22) {
        top: 185px;
        left: 27rem;
        transition-delay: 2.75s
    }
    
    .home-card {
        background-color: #fff
    }
    
    .portfolio-pr-el .container-el .el .illu {
        height: 220px;
    }
    
    .portfolio-pr-el .container-el .el .wrapper .container-img {
        width: calc(100% - 50px);
        height: auto;
        min-height: 63.1vw;
    }
    
    .about-company .grid-image .background-inner {
        height: 40vh;
    }
    
    .portfolio-pr-el .container-el .el .wrapper .container-img {
        width: calc(100% - 50px);
        height: auto;
        min-height: 63.1vw;
    }
    
    .about-company .grid-image .background-inner {
        height: 40vh;
    }
    
    .post-wrapper .post {
        margin-left: 30px !important;
      }
    
    .post-wrapper .post .post-content {
        padding: 0 25px;
      }
    
    .portfolio-pr-el .container-el .el .title {
        mix-blend-mode: difference;
    }
    
    .fly-menu .fly-menu-inner .menu-wrapper .container-nav li a .link-line  {
        display: none;
    }
    
    .modal .modal-dialog {
        margin: 30px;
    }

    .nav-tabs.tech-navbar .nav-item a {
        border: 1px solid #ffffff30;
    }

    .footer-listing li a {
        font-size: 1.2rem;
        padding: 0.25rem 0;
    }
}

@supports (-webkit-touch-callout: none) and (max-width:575px) {
    /* CSS specific to iOS devices */ 
    .hero-slides .carousel-el.apps-cell .container-illu.mobile .container-device .el-device {
        max-width: 60px;
    }
  }

  @media(max-width:575px) {
    .inner-hero-heading {
        font-size: 42px;
        line-height: 44px;
    }
  }